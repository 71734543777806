html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 160px !important;
  /* margin-bottom = footer height */
}

footer {
  position: absolute !important;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  overflow: hidden;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
